import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Session } from '@supabase/supabase-js';

const initialState = {
    session: null
};

export const supabaseSlice = createSlice({
    name: 'supabase',
    initialState,
    reducers: {
        setSession: (state, action) => {
            state.session = action.payload;
        }
    }
});

export const { setSession } = supabaseSlice.actions;

export default supabaseSlice.reducer;