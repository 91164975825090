import * as Yup from 'yup';
// form
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
// @mui
import {Alert, Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useSnackbar} from "notistack";
// routes
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import {FormProvider, RHFCheckbox, RHFTextField} from '../../../components/hook-form';
import {useSupabase} from "../../../contexts/SupabaseContext";

// ----------------------------------------------------------------------

export default function LoginForm() {
    const {signInMagicLink} = useSupabase();
    const {enqueueSnackbar} = useSnackbar();


    const isMountedRef = useIsMountedRef();


    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        // password: Yup.string().required('Password is required'),
    });

    const defaultValues = {
        email: '', remember: true,
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema), defaultValues,
    });

    const {
        reset, setError, handleSubmit, formState: {errors, isSubmitting},
    } = methods;

    const onSubmit = async (data) => {
        try {
            const res = await signInMagicLink(data.email)
            if (res) {
                enqueueSnackbar('Check your email!');
                reset();
            }
        } catch (error) {
            reset();
            if (isMountedRef.current) {
                console.log(error)
                setError('afterSubmit', error);
                console.log(errors)
            }
        }
    };

    return (<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.name}</Alert>}

            <RHFTextField name="email" label="Email address"/>

            {/*<RHFTextField*/}
            {/*    name="password"*/}
            {/*    label="Password"*/}
            {/*    type={showPassword ? 'text' : 'password'}*/}
            {/*    InputProps={{*/}
            {/*        endAdornment: (<InputAdornment position="end">*/}
            {/*            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">*/}
            {/*                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>*/}
            {/*            </IconButton>*/}
            {/*        </InputAdornment>),*/}
            {/*    }}*/}
            {/*/>*/}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>
            <RHFCheckbox name="remember" label="Remember me"/>
            {/* <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}> */}
            {/*  Forgot password? */}
            {/* </Link> */}
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Login
        </LoadingButton>
    </FormProvider>);
}
