import {createContext, useContext, useEffect, useState} from 'react'
import {useSupabaseClient, useUser} from "@supabase/auth-helpers-react";
import {v4 as uuidv4} from 'uuid';
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom';
import {setSession} from "../redux/slices/supabase";


const SupabaseContext = createContext({
    supabase: null,
    user: null,
    cmsAllowed: true,
    isInitialised: false,
    isAuthenticated: false,
    role: null,
    signIn: async () => {
    },
    signUp: async () => {
    },
    signOut: async () => {
    },
})

export const useSupabase = () => useContext(SupabaseContext)


const SupabaseProvider = ({children}) => {
    const [isInitialised, setIsInitialised] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const userHelper = useUser();
    const supabase = useSupabaseClient();
    const [user, setUser] = useState(null)
    const [userDetails, setUserDetails] = useState(null);
    const [activeUsers, setActiveUsers] = useState([]);
    const [loadingActiveUsers, setLoadingActiveUsers] = useState(true);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        supabase.auth.getSession().then(({data: {session}}) => {
            dispatch(setSession(session));
        })
        const {data: authSubscription} = supabase.auth.onAuthStateChange(
            async (event, session) => {
                if (session?.user) setUser(session.user);
            }
        );
        return () => {
            authSubscription.subscription.unsubscribe();
        };
    }, [dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            if (user && user.id) {
                try {
                    const {data, error} = await supabase
                        .from('active_users')
                        .select('*')
                        .eq('user_id', user.id).single()
                        .throwOnError();
                    if (data) {
                        setUserDetails(data);
                        setIsInitialised(true)
                        setIsAuthenticated(true)
                    }
                    if (error) {
                        console.error('Error:', error);
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        };
        fetchData(); // Call the async function when the useEffect is triggered
    }, [user]);

    const signInMagicLink = async (email) => {
        const {data, error} = await supabase.auth.signInWithOtp({
            email,
            options: {
                // emailRedirectTo: `${window.location.origin}/dashboard`,
                // emailRedirectTo: `http://localhost:3000/dashboard`,
                emailRedirectTo: `https://manage.air-aware.co.uk/dashboard`,
            },
        })
        if (data) return true;
        throw error;
    }

    const getActiveUsers = async () => {
        setLoadingActiveUsers(true);
        const res = await supabase.functions.invoke('get-users', {body: {user_id: user.id}})
        if (res.data.error) {
            setLoadingActiveUsers(false);
            throw res.data.error
        }
        setActiveUsers(res.data.users);
        setLoadingActiveUsers(false);
        return true;
    }


    const createActiveUser = async (credentials) => {
        const {email, name, role, council} = credentials
        const res = await supabase.functions.invoke('create-user', {
            body: {email, name, role, council, user_id: user.id},
        })
        if (res.data.error) throw res.data.error
        return true;
    }

    const deleteActiveUser = async (userIDToDelete) => {
        const res = await supabase.functions.invoke('delete-user', {
            body: {user_id: user.id, user_to_delete_id: userIDToDelete},
        })
        if (res.data.error) throw res.data.error
        await getActiveUsers();
        return true;
    }

    const signOut = async () => {
        const response = await supabase?.auth.signOut()
        if (response?.error) {
            throw response.error
        }
        setIsAuthenticated(false)
        dispatch(setSession(null));
        navigate('/')
    }

    async function updateUserInfo(newName) {
        const {error: updateError} = await supabase
            .from('active_users')
            .update({name: newName})
            .eq('user_id', user.id)

        if (updateError) {
            return false
        }
        const newUserDetails = {...userDetails};
        newUserDetails.name = newName;
        setUserDetails(newUserDetails)
        return true
    }

    return (
        <SupabaseContext.Provider
            value={{
                supabase,
                user,
                userDetails,
                isAuthenticated,
                isInitialised,
                activeUsers,
                loadingActiveUsers,
                events,
                signOut,
                createActiveUser,
                signInMagicLink,
                updateUserInfo,
                getActiveUsers,
                deleteActiveUser,
            }}>
            {children}
        </SupabaseContext.Provider>
    )
}

export {SupabaseContext, SupabaseProvider};
