// routes
import {PATH_DASHBOARD} from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{width: 1, height: 1}}/>;

const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    booking: getIcon('ic_booking'),
};


const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
        subheader: 'User Management',
        items: [
            {
                title: 'user',
                path: PATH_DASHBOARD.user.root,
                icon: ICONS.user,
                roles: ['superadmin', 'admin', 'manager', 'member'],
                children: [
                    {
                        title: 'my account',
                        path: PATH_DASHBOARD.user.account,
                        roles: ['superadmin', 'admin', 'manager', 'member'],
                    },
                    {
                        title: 'active users',
                        path: PATH_DASHBOARD.user.active,
                        roles: ['superadmin', 'admin', 'manager'],
                    },
                    {title: 'create', path: PATH_DASHBOARD.user.newUser, roles: ['superadmin', 'admin', 'manager'],},
                ],
            },
        ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
        subheader: 'events',
        items: [
            {
                roles: ['superadmin', 'admin', 'manager', 'member'],
                title: 'calendar', path: PATH_DASHBOARD.events, icon: ICONS.calendar,
            },
        ],
    },

];

export default navConfig;
