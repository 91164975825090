import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import eventsReducer from './slices/events';
import supabaseReducer from './slices/supabase';
import calendarReducer from './slices/calendar';

// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

const supabasePersistConfig = {
    key: 'supabase',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['session'],
};

const eventsPersistConfig = {
    key: 'events',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['session'],
};

const rootReducer = combineReducers({
    calendar: calendarReducer,
    supabase: persistReducer(supabasePersistConfig, supabaseReducer),
    events: persistReducer(eventsPersistConfig, eventsReducer),
});

export {rootPersistConfig, rootReducer};
