import {useContext} from 'react';
//
import {useSupabase} from '../contexts/SupabaseContext';
// ----------------------------------------------------------------------

const useAuth = () => {
  const context = useSupabase;

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export default useAuth;
