import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {Box} from '@mui/material';
import {ReactComponent as AirAwareLogo} from "../assets/air_aware_logo.svg";
// ----------------------------------------------------------------------

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default function Logo({disabledLink = false, sx}) {
    const logo = (
        <Box sx={{width: 40, height: 40, borderRadius: 50, ...sx}}>
            <AirAwareLogo style={{ height: 53, width: 36, ...sx }}/>
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}
