import {createSlice} from '@reduxjs/toolkit';
// utils
import {v4 as uuidv4} from "uuid";
//
import {dispatch} from '../store';
import {supabaseClient} from "../../index";

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    events: [],
    isOpenModal: false,
    selectedEventId: null,
    selectedRange: null,
    selectedEventImage: null,
};


const slice = createSlice({
    name: 'calendar', initialState, reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET EVENTS
        getEventsSuccess(state, action) {
            state.isLoading = false;
            state.events = action.payload;
        },

        // CREATE EVENT
        createEventSuccess(state, action) {
            const newEvent = action.payload;
            state.isLoading = false;
            state.events = [...state.events, newEvent];
        },

        // UPDATE EVENT
        updateEventSuccess(state, action) {
            const {event} = action.payload;
            const updateEvent = state.events.map((_event) => {
                if (String(_event.id) === String(event.id)) {
                    return event;
                }
                return _event;
            });

            state.isLoading = false;
            state.events = updateEvent;
        },

        // DELETE EVENT
        deleteEventSuccess(state, action) {
            const {eventId} = action.payload;
            state.events = state.events.filter((event) => String(event.id) !== String(eventId));
        },

        // SELECT EVENT
        selectEvent(state, action) {
            state.selectedEventId = action.payload;
            state.isOpenModal = true;
        },

        setSelectedEventImage(state, action) {
            const {publicUrl} = getImage('events', action.payload)
            state.selectedEventImage = publicUrl
        },

        // SELECT RANGE
        selectRange(state, action) {
            const {start, end} = action.payload;
            state.isOpenModal = true;
            state.selectedRange = {start, end};
        },

        // OPEN MODAL
        openModal(state) {
            state.isOpenModal = true;
        },

        // CLOSE MODAL
        closeModal(state) {
            state.isOpenModal = false;
            state.selectedEventId = null;
            state.selectedRange = null;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {openModal, closeModal, selectEvent, setSelectedEventImage} = slice.actions;

// ----------------------------------------------------------------------

export function getEvents() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const { data, error } = await supabaseClient
                .from('events')
                .select('*')
                .throwOnError();

            if (error) throw error;

            // Expand events with additional dates
            const expandedEvents = data.flatMap(event => {
                const additionalDates = event.additional_dates ? JSON.parse(event.additional_dates) : [];
                return [
                    event,
                    ...additionalDates.map(date => ({
                        ...event,
                        start: date.start,
                        end: date.end,
                        isAdditionalDate: true
                    }))
                ];
            });

            dispatch(slice.actions.getEventsSuccess(expandedEvents));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createEvent(newEvent) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const {title, description, image, start, end, council, additional_dates} = newEvent
            const trimmedTitle = title.trim();
            const uuid = uuidv4()
            const sanitisedTitle = trimmedTitle.replace(/[^a-zA-Z0-9-_]/g, '_');
            const filePath = await uploadImage(image.file, 'events', `${uuid}-${sanitisedTitle}`)
            const imageUrl = getImage('events', filePath)

            const eventData = {
                title: trimmedTitle,
                description,
                image: imageUrl,
                start: start.toISOString(),
                end: end.toISOString(),
                council,
                additional_dates: additional_dates?.length > 0 ? JSON.stringify(additional_dates.map(date => ({
                    start: date.start.toISOString(),
                    end: date.end.toISOString()
                }))) : null
            };

            const {data, error} = await supabaseClient
                .from('events')
                .insert(eventData)
                .select();

            if (error) {
                console.error(error)
                return false
            }
            dispatch(slice.actions.createEventSuccess(data[0]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateEvent(updateEvent, newImage) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const {id, title, description, image, start, end, council, additional_dates} = updateEvent;
            let imageUrl = ''
            if (newImage) {
                const uuid = uuidv4()
                const filePath = await uploadImage(image.file, 'events', `${uuid}-${title}`)
                imageUrl = getImage('events', filePath)
            }
            const updates = {
                title,
                description,
                image: newImage ? imageUrl : image,
                start: start.toISOString(),
                end: end.toISOString(),
                council,
                additional_dates: additional_dates?.length > 0 ? JSON.stringify(additional_dates.map(date => ({
                    start: date.start.toISOString(),
                    end: date.end.toISOString()
                }))) : null
            };
            const {data, error} = await supabaseClient
                .from('events')
                .upsert({id, ...updates});

            dispatch(slice.actions.updateEventSuccess({event: {id, ...updates}}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const {data, error} = await supabaseClient
                .from('events')
                .delete()
                .eq('id', eventId);

            if (error) {
                throw error;
            }
            dispatch(slice.actions.deleteEventSuccess({eventId}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function selectRange(start, end) {
    return async () => {
        dispatch(slice.actions.selectRange({
            start: start.getTime(), end: end.getTime(),
        }));
    };
}

async function uploadImage(file, bucket, fileNamePath) {
    const {data, error} = await supabaseClient
        .storage
        .from(bucket)
        .upload(fileNamePath, file)
    if (data) return data.path
    throw error
}

export function getImage(bucket, fileNamePath) {
    const {data, error} = supabaseClient.storage.from(bucket).getPublicUrl(fileNamePath);
    if (!error) return data.publicUrl
    throw error
}