// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    register: path(ROOTS_AUTH, '/register'),
    emailConfirmed: path(ROOTS_AUTH, '/email-confirmed'),
    verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
    pricing: '/pricing',
    payment: '/payment',
    about: '/about-us',
    contact: '/contact-us',
    faqs: '/faqs',
    page404: '/404',
    page500: '/500',
    components: '/components'
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    user: {
        root: path(ROOTS_DASHBOARD, '/user'),
        active: path(ROOTS_DASHBOARD, '/user/active'),
        newUser: path(ROOTS_DASHBOARD, '/user/new'),
        account: path(ROOTS_DASHBOARD, '/user/account')
    },
    events: path(ROOTS_DASHBOARD, '/events'),
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
