import {createSlice} from '@reduxjs/toolkit';

import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    liveEvents: [],
    expiredEvents: []
};

const slice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    startLoading
} = slice.actions;

// ----------------------------------------------------------------------

export function setLiveEvents(liveEvents) {

    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            // state.liveEvents = liveEvents;
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}